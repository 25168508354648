import { css, html } from 'lit';
import { styleMap } from 'lit/directives/style-map.js';

import Overlay from '../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import { objToName } from '../../../../packages/neb-utils/formatters';
import {
  CSS_BORDER_GREY_2,
  CSS_COLOR_BLACK,
  CSS_COLOR_ERROR,
  CSS_COLOR_GREY_1,
  CSS_SPACING,
} from '../../../styles';

import '../../../../packages/neb-lit-components/src/components/neb-popup-header';
import '../../../../packages/neb-lit-components/src/components/neb-text';
import '../../misc/neb-icon';

const NAME_OPTS = {
  reverse: true,
  middleInitial: true,
  preferred: true,
};

export const ELEMENTS = {
  cancelButton: { id: 'cancel-button' },
  claimInfo: { id: 'claim-info' },
  form: { id: 'form' },
  header: { id: 'header' },
  leftPanel: { id: 'left-panel' },
  claimNumberHeader: { id: 'claim-header-number' },
  patientName: { id: 'patient-name' },
  serviceDate: { id: 'service-date' },
  provider: { id: 'provider' },
  payer: { id: 'payer' },
  lastUpdated: { id: 'last-updated' },
  errorDescriptions: { id: 'error-descriptions' },
};

export class NebOverlayClaimError extends Overlay {
  static get properties() {
    return {
      widthRatio: String,
    };
  }

  initState() {
    super.initState();

    this.widthRatio = '50/50';

    this.model = {
      claimNumber: '',
      patient: {},
      serviceDate: '',
      provider: {},
      payer: '',
      errorDescriptions: '',
    };
  }

  initHandlers() {
    super.initHandlers();

    this.__handlers = {
      ...this.handlers,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: 100%;
        }

        .claim-info {
          padding: 0 ${CSS_SPACING} ${CSS_SPACING};
          border-bottom: ${CSS_BORDER_GREY_2};
          margin-top: ${CSS_SPACING};
        }

        .claim-info-container {
          border: ${CSS_BORDER_GREY_2};
          border-radius: 5px;
          display: flex;
          margin-bottom: ${CSS_SPACING};
          padding: ${CSS_SPACING};
        }

        .descriptions {
          padding: 10px ${CSS_SPACING} 0;
        }

        .field {
          width: 50%;
        }

        .form {
          padding: 0 ${CSS_SPACING};
        }

        .header {
          display: flex;
          justify-content: space-between;
          padding: ${CSS_SPACING};
        }

        .header-text {
          display: flex;
          gap: 20px;
        }

        .header-title {
          font-size: 16px;
          font-weight: bold;
          color: ${CSS_COLOR_BLACK};
        }

        .ellipse-field {
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .error {
          color: ${CSS_COLOR_ERROR};
        }

        .icon {
          cursor: pointer;
          width: 24px;
          height: 24px;
          fill: ${CSS_COLOR_GREY_1};
        }

        .panels {
          display: flex;
          height: 100%;
          border-top: ${CSS_BORDER_GREY_2};
          min-height: 0;
        }

        .label {
          font-weight: bold;
        }

        .left-panel {
          border-right: ${CSS_BORDER_GREY_2};
          margin-bottom: ${CSS_SPACING};
          padding: ${CSS_SPACING};
          overflow-y: auto;
          height: 100%;
        }

        .right-panel {
          display: flex;
          flex-direction: column;
        }
      `,
    ];
  }

  getPanelWidths() {
    const [leftWidth, rightWidth] = this.widthRatio.split('/');

    return {
      leftPanelWidth: `${leftWidth}%`,
      rightPanelWidth: `${rightWidth}%`,
    };
  }

  __renderHeader() {
    return html`
      <div class="header">
        <div class="header-text">
          <span id="${ELEMENTS.claimNumberHeader.id}" class="header-title"
            >Claim Number: ${this.model.claimNumber}</span
          >
          <span class="header-title error">Validation Error</span>
        </div>

        <neb-icon
          id="${ELEMENTS.cancelButton.id}"
          class="icon"
          icon="neb:close"
          @click="${this.__handlers.dismiss}"
        ></neb-icon>
      </div>
    `;
  }

  __renderPanels() {
    const { leftPanelWidth } = this.getPanelWidths();

    return html`
      <div class="panels">
        <div class="left-panel" style="${styleMap({ width: leftPanelWidth })}">
          ${this.renderLeftPanel()}
        </div>
        ${this.__renderRightPanel()}
      </div>
    `;
  }

  __renderRightPanel() {
    const { rightPanelWidth } = this.getPanelWidths();

    return html`
      <div class="right-panel" style="${styleMap({ width: rightPanelWidth })}">
        ${this.__renderClaimInfo()} ${this.renderForm()}
      </div>
    `;
  }

  __renderClaimInfoContainer() {
    return html`
      <div class="claim-info-container">
        <div class="field">
          <div id="${ELEMENTS.patientName.id}" class="ellipse-field">
            <neb-text class="label">Patient: </neb-text>
            ${objToName(this.model.patient.name, NAME_OPTS)}
          </div>
          <div id="${ELEMENTS.provider.id}" class="ellipse-field">
            <neb-text class="label">Provider: </neb-text>
            ${objToName(this.model.provider.name, NAME_OPTS)}
          </div>
          <div id="${ELEMENTS.payer.id}">
            <neb-text class="label">Payer: </neb-text> ${this.model.payer}
          </div>
        </div>
        <div class="field">
          <div id="${ELEMENTS.serviceDate.id}">
            <neb-text class="label">Service Date: </neb-text> ${
              this.model.serviceDate
            }
          </div>
          <div id="${ELEMENTS.lastUpdated.id}">
            <neb-text class="label">Last Updated: </neb-text> ${
              this.model.lastUpdated
            }
          </div>
        </div>
      </div>
    `;
  }

  __renderErrors() {
    const errors = this.model.errorDescriptions.map(
      err => html`
        <div>${err}</div>
      `,
    );

    return html`
      <div class="label">Errors:</div>
      <div id="${ELEMENTS.errorDescriptions.id}" class="descriptions">
        ${errors}
      </div>
    `;
  }

  __renderClaimInfo() {
    return html`
      <div class="claim-info" id="${ELEMENTS.claimInfo.id}">
        ${this.__renderClaimInfoContainer()} ${this.__renderErrors()}
      </div>
    `;
  }

  renderForm() {
    return html`
      <div class="form" id="${ELEMENTS.form.id}"></div>
    `;
  }

  renderLeftPanel() {
    return html`
      <div id="${ELEMENTS.leftPanel.id}"></div>
    `;
  }

  renderContent() {
    return html`
      ${this.__renderHeader()} ${this.__renderPanels()}
    `;
  }
}

customElements.define('neb-overlay-claim-error', NebOverlayClaimError);
